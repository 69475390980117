.App-header {
    min-height:fit-content;
    height: auto;
    max-height: 100%;
    overflow:auto;
    display: flex;
    align-items: flex-start;
    justify-content:space-around;
    align-content: center;
    padding: 1rem;
    font-family: "Alloy-Regular";
    position: relative;
    top: 0;
    left: 0;
  }
.Img-header {
    width:48vmax;
}
.Menu-title{
    font-family: "Alloy-Regular";
    font-weight: 100px;
    font-size: 15px;
    color: #F95A32;
    text-shadow:2px 2px 3px #000000;
    text-align: center;
    margin-top: 30vmax;
  }